import React, { lazy, Suspense} from 'react';
import './App.scss';
import { Container } from 'react-bootstrap';
import Footer from './components/Footer';
import { BrowserRouter, Routes, Route } from "react-router-dom";
const Home = lazy(() => import('./pages/Home'));
const StudentInformation = lazy(() => import('./pages/StudentInformation'));
const QuestionnaireAnswer = lazy(() => import('./pages/Answer'));

function App() {
  return (
    <Container fluid className="p-0">
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route path="/responder/:slug" element={<QuestionnaireAnswer />} />
            <Route path="/informacion/:slug" element={<StudentInformation />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
        <Footer></Footer>
      </Suspense>
    </Container>
  );
}

export default App;
