import '../styles/footer.scss';

function Footer(){    
    return (
        <div className="footer">
            <p>
                Si presentas alguna dificultad con la plataforma, escríbenos a: <b>alejandra.medina@designamx.com</b>
            </p>
        </div>
    )
}

export default Footer;